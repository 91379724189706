import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';

@Component({
  selector: 'app-alerta-pedido',
  templateUrl: './alerta-pedido.page.html',
  styleUrls: ['./alerta-pedido.page.scss'],
})
export class AlertaPedidoPage implements OnInit {

  store:any;
  adrress:any;
  cargo:any;
  total:any;
  id :any;
    
  constructor(public navParams: NavParams,public modalController: ModalController,private nativeAudio: NativeAudio,private vibration: Vibration) {
  
    this.id = navParams.get('id');
    this.store 	= navParams.get('store');
    this.adrress = navParams.get('adrress');
    this.cargo 	= navParams.get('cargo');
    this.total 	= navParams.get('total');


    this.nativeAudio.preloadComplex('trackID', 'assets/alert.mp3', 1, 1, 0).then(function() {
      console.log("audio loaded!");
  }, function(err) {
      console.log("audio failed: " + err);
  });

    
   }

  ngOnInit() {
    this.playAudio();

  }

  playAudio() {
    this.vibration.vibrate (1000);
    var audio = new Audio('assets/alert.mp3');
    audio.play();

    this.nativeAudio.play('trackID').then(function() {
        console.log("playing audio!");
    }, function(err) {
        console.log("error playing audio: " + err);
    });
}


async asignar()
{
  await this.modalController.dismiss({id:this.id});
}

  async closeModal() {
        await this.modalController.dismiss({data:true});
  }
}
