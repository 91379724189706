import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
   // loadChildren: './route-cliente/route-cliente.module#RouteClientePageModule' 
   loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  { path: 'login', loadChildren: './account/login/login.module#LoginPageModule' },
  { path: 'forgot', loadChildren: './account/forgot/forgot.module#ForgotPageModule' },
  { path: 'profile', loadChildren: './account/profile/profile.module#ProfilePageModule' },
  { path: 'order', loadChildren: './account/order/order.module#OrderPageModule' },
  { path: 'lang', loadChildren: './lang/lang.module#LangPageModule' },
  { path: 'detail', loadChildren: './detail/detail.module#DetailPageModule' },
  { path: 'route', loadChildren: './route-cliente/route-cliente.module#RouteClientePageModule' },
  { path: 'pedidos', loadChildren: './pedidos/pedidos.module#PedidosPageModule' },
  { path: 'alerta-pedido', loadChildren: './alerta-pedido/alerta-pedido.module#AlertaPedidoPageModule' },
  { path: 'qr', loadChildren: './qr/qr.module#QrPageModule' },  { path: 'cajas-menores', loadChildren: './cajas-menores/cajas-menores.module#CajasMenoresPageModule' },
  { path: 'detalle-banco', loadChildren: './detalle-banco/detalle-banco.module#DetalleBancoPageModule' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
